<template>
    <div class="container-link">
        <a class="link" @click="pushToForgotPassword">Esqueci minha senha</a>
    </div>
</template>

<script>
export default {
  name: 'LinkPassword',
  methods: {
    pushToForgotPassword() {
      this.$router.push({ name: 'forgotPassword' });
    },
  },
};
</script>

<style lang="scss" scoped>
    .container-link {
        padding-left: 0%;
    }
    .link {
        color: #404040;
        font-weight: 300;
        text-decoration: underline;
    }

    @media only screen and (max-device-width: 700px)  {
/* For mobile phones: */
  }

</style>
