<template>
  <TemplateLogin>
    <v-form class="px-md-8" ref="form" @submit.prevent="submit">
        <v-row class="ma-0">
          <v-col cols="12" md="12" class="pa-md-0 text pa-1" align="center">
            Escolha de qual forma quer efetuar seu login:
          </v-col>
          <v-col cols="6" class="pl-0">
            <v-btn
              block dark
              color="#282E62"
              @click="setTypeLogin('email')"
              :outlined="typeLogin === 'cnpj'"
              >
              E-mail
            </v-btn>
          </v-col>
          <v-col cols="6" class="pr-0">
            <v-btn
              block dark
              color="#282E62"
              :outlined="typeLogin === 'email'"
              @click="setTypeLogin('cnpj')"
              >
              CNPJ
            </v-btn>
          </v-col>
        </v-row>
        <v-text-field
          v-if="typeLogin === 'cnpj'"
          @keydown.enter="handleLogin"
          v-model.trim="form.login"
          autofocus
          placeholder="Insira seu CNPJ"
          label="CNPJ"
          type="text"
          class="cnpj"
          v-mask="'##.###.###/####-##'"
          :rules="validateLogin">
        </v-text-field>
        <v-text-field
          v-show="typeLogin === 'email'"
          @keydown.enter="handleLogin"
          v-model.trim="form.login"
          autofocus
          placeholder="Insira seu e-mail"
          label="E-mail"
          type="text"
          class="cnpj"
          :rules="typeLogin === 'email' ? validateEmail : []">
        </v-text-field>
        <v-text-field
          @keydown.enter="handleLogin"
          v-model.trim="form.password"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          label="Senha"
          placeholder="Insira sua senha"
          class="password"
          hide-details="auto"
          :rules="validatePassword">
        </v-text-field>
        <LinkPassword class="mt-2" />
        <v-btn
          rounded large block dark
          color="#D70472"
          @click="handleLogin()"
          class="mb-3 mt-6"
        >
          Entrar
        </v-btn>
        <v-btn
          rounded large block dark
          color="#D70472"
          outlined
          class="mb-3"
          @click="toFirstAccess"
        >
          Primeiro acesso
        </v-btn>
        <v-col cols="12" class="pa-0" align="center">
          <LinkRegister />
        </v-col>
    </v-form>
  </TemplateLogin>      
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import LinkPassword from '../../components/LinkPassword.vue';
import LinkRegister from '../../components/LinkRegister.vue';
import service from '../../service';
import TemplateLogin from './TemplateLogin'

export default {
  name: 'login',
  directives: { mask },
  components: { LinkPassword, LinkRegister, TemplateLogin },
  data: () => ({
    form: {
      login: '',
      password: '',
    },
    alert: false,
    showPassword: false,
    alertCreated: false,
    typeLogin: 'email'
  }),
  watch: {
    error(value) {
      this.alert = value;
    }
  },
  methods: {
    ...mapActions({
      login: 'Auth/login',
    }),
    ...mapMutations({
      clearError: "Auth/CLEAR_ERROR"
    }),
    async handleLogin() {
      const vm = this;
      vm.alert = false;
      if (this.$refs.form.validate()) { 
        const payload = {
          login: this.typeLogin == 'cnpj' ? service.cleanDocument(vm.form.login) : vm.form.login,
          password: vm.form.password,
          target: 'customer'
        };
        if(this.typeLogin === 'cnpj') delete payload.target
        await this.login(payload);
        if (!this.error) {
          this.$router.push({ name: `customer home` });
        }
      }
    },
    setTypeLogin(type){
      if(this.typeLogin !== type){
        this.form.login = ""
        this.form.password = ""
        this.typeLogin = type
      }
    },
    toFirstAccess(){
      this.$router.push({ name: 'firstAccess' });
    }
  },
  computed: {
    ...mapGetters({
      error: 'Auth/getError',
    }),
    createdLogin() {
      return this.$store.state.Auth.messages;
    },
    validateLogin() {
      return [valueInput => !!valueInput || 'CNPJ obrigatório'];
    },
    validatePassword() {
      return [valueInput => !!valueInput || 'Senha obrigatória'];
    },
    validateEmail(){
      return [
          valueInput => !!valueInput || "E-mail obrigatório",
          valueInput => /.+@.+\..+/.test(valueInput) || "E-mail inválido",
      ]
    }
  },
  destroyed() {
    this.createdLogin.created = false;
    this.clearError()
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/login.scss';
</style>
